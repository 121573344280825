<template>
  <div class="farm-list">
    <vue-element-loading
      :active="loading"
      :text="loadingText"
      spinner="bar-fade-scale"
      color="var(--secondary)"
    />
    <h6 class="main-title">لیست زمین های ثبت شده توسط حقوقی</h6>
    <div class="farm-list-box">
      <div v-if="farmList.length > 0">
        <div class="farmItem" v-for="(item, index) in farmList" :key="index">
          <img
            class="farmImg"
            :src="item.image"
            @error="
              () => (item.image = require(`@/assets/img/farmImageMapBox.jpg`))
            "
          />
          <img
            class="productImg"
            @error="
              () => (item.productImage = require(`@/assets/img/notFound.png`))
            "
            :src="item.productImage"
            alt=""
          />
          <ul>
            <li>{{ item.title }}</li>
            <li>{{ item.product }}</li>
            <li>{{ item.stateCity }}</li>
            <li>{{ item.districtVilage }}</li>
          </ul>
          <button
            @click="LoadSubForm('farmDetail', item.id)"
            :class="
              id == item.id && subForm == 'farmDetail'
                ? 'farmItemBtn selected'
                : 'farmItemBtn'
            "
          >
            جزئیات
          </button>
          <button
            @click="LoadSubForm('legalEntityUsers', item.id)"
            :class="
              id == item.id && subForm == 'legalEntityUsers'
                ? 'farmItemBtn selected'
                : 'farmItemBtn'
            "
          >
            همکاران
          </button>
          <button
            @click="LoadSubForm('lastAdvice', item.id)"
            :class="
              id == item.id && subForm == 'lastAdvice'
                ? 'farmItemBtn selected'
                : 'farmItemBtn'
            "
          >
            توصیه ها
          </button>
          <button
            @click="LoadSubForm('financialReport', item.id)"
            :class="
              id == item.id && subForm == 'financialReport'
                ? 'farmItemBtn selected'
                : 'farmItemBtn'
            "
          >
            مالی
          </button>
        </div>
      </div>
      <div v-else class="emptyBox">
        <h6>حقوقی هیچ زمینی ثبت نکرده است</h6>
      </div>
    </div>
  </div>
</template>

<script>
import { apiUrlRoot } from "../../../constants/config";
import { mapActions } from "vuex";
import { getCurrentUser } from "../../../utils/index";
export default {
  components: {},
  data() {
    return {
      loading: false,
      loadingText: "دریافت اطلاعات از سرور...",
      farmList: [],
      id: 0,
      subForm: "",
    };
  },
  computed: {},
  methods: {
    ...mapActions("connectionFarmerLegalEntity", ["GetAllLegalEntityFarms"]),

    async GetAll() {
      this.farmList = [];
      this.loading = true;
      this.loadingtext = "دریافت اطلاعات از سرور...";
      var result = await this.GetAllLegalEntityFarms({
        userType: getCurrentUser().type,
      });
      this.loading = false;
      this.farmList = result.data;
      this.farmList.forEach((element) => {
        element.image = `${apiUrlRoot}Farm/${element.image}`;
        element.productImage = `${apiUrlRoot}productImage/${element.productImage}`;
      });
      console.log("GetAllLegalEntityFarms", result);
    },
    LoadSubForm(subForm, farmId) {
      this.subForm = subForm;
      this.id = farmId;
      this.$emit("subForm", subForm, farmId);
    },
  },
  mounted() {
    this.GetAll();
  },
  created() {},
};
</script>

    <style scoped>
.farm-list {
  width: 30vw;
  height: 90vh;
  float: right;
  overflow: hidden;
}
.main-title {
  width: 98%;
  text-align: center;
  font-size: 17px;
  float: right;
  margin: 3px 5px;
  color: #00bb3e;
  background-color: #fff;
  padding: 5px 0px;
}
.farm-list-box {
  width: 98%;
  height: 85vh;
  overflow-x: hidden;
  overflow-y: auto;
  margin-right: 1%;
  background-color: #fff;
}
.bodyItem {
  float: right;
  clear: both;
  padding: 6px;
  min-height: 25vh;
}

.bodyItemValue {
  float: right;
  clear: both;
  border-right: 1px solid #a196fb;
  margin-top: 3px;
  padding-right: 6px;
  padding-top: 9px;
  background-color: #efefef;
  width: 100%;
}

.userList {
  width: 13vw;
  border: none;
  border-bottom: 1px solid darkgreen;
  color: darkgreen;
  outline: none;
}

.farmItem {
  width: 97%;
  float: right;
  background-color: #fff;
  margin: 0.5em;
  border: 1px solid #bbb;
  cursor: pointer;
  box-shadow: 0px 3px 10px -5px #000;
  position: relative;
  height: 20vh;
  border-radius: var(--border-radius);
}

.farmItem .farmImg {
  float: right;
  width: 40%;
  height: 19vh;
  margin: 1%;
}
.farmItem .productImg {
  width: 9%;
  position: absolute;
  top: 9em;
  right: 1em;
}

.farmItem ul {
  float: right;
  margin: 0em;
  width: 56%;
  list-style: none;
  padding: 0em 0.5em;
}

.farmItem ul li {
  float: right;
  width: 100%;
  text-align: right;
  margin: 4px 0px;
  padding-right: 0.2em;
  font-family: "Dirooz";
  color: black;
  font-size: 1em;
  background-color: whitesmoke;
  padding: 0.3em 0.2em;
}

.farmItemBtn {
  width: 13%;
  height: 3vh;
  text-align: center;
  font-size: 11px;
  border: none;
  color: rgb(255 255 255);
  background-color: #2eb85c;
  border-radius: 10px 10px 0px 0px;
  transition: all 500ms;
  font-weight: bold;
  margin-right: 3px;
}
.farmItemBtn:hover {
  background-color: #f80;
}
.farmItemBtn.selected {
  background-color: #f80;
}
.emptyBox h6 {
  font-size: 1.5em;
  color: #b7b7b7;
  margin-top: 5em;
  margin-right: 4em;
  font-family: inherit;
}
</style>
