<template>
  <div class="main-box">
    <legalEntityFarms @subForm="loadSubForm" />
    <component v-if="subForm != null" v-bind:is="subForm" :farmId="farmId" />
  </div>
</template>

<script>
import farmDetail from "./subForm/farmDetail";
import financialReport from "./subForm/financialReport";
import lastAdvice from "./subForm/lastAdvice";
import legalEntityFarms from "./subForm/legalEntityFarms";
import legalEntityUsers from "./subForm/legalEntityUsers";

export default {
  components: {
    farmDetail,
    financialReport,
    lastAdvice,
    legalEntityFarms,
    legalEntityUsers,
  },
  data() {
    return {
      subForm: null,
      farmId: 0,
    };
  },
  computed: {},
  methods: {
    loadSubForm(name, farmId) {
      this.subForm = name;
      this.farmId = farmId;
    },
  },
  mounted() {},
  created() {},
};
</script>

<style scoped>
.main-box {
  width: 100%;
  height: 83vh;
  float: right;
  overflow: hidden;
}
</style>