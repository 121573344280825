<template>
  <div class="detail-box">
    <vue-element-loading
      :active="loading"
      :text="loadingText"
      spinner="bar-fade-scale"
      color="var(--secondary)"
    />
    <h6 class="detail-title">جزئیات زمین</h6>
  </div>
</template>

  <script>
import { mapActions } from "vuex";
export default {
  components: {},
  props: ["farmId"],
  data() {
    return {
      loading: false,
      loadingText: "دریافت اطلاعات از سرور...",
    };
  },
  watch: {
    farmId: function () {
      this.GetDetail();
    },
  },
  computed: {},
  methods: {
    ...mapActions("connectionFarmerLegalEntity", ["GetFarmDetail"]),

    async GetDetail() {
      this.loading = true;
      this.loadingText = "دریافت اطلاعات از سرور...";
      var result = await this.GetFarmDetail({
        id: this.farmId,
      });
      this.loading = false;
      console.log("result", result);
    },
  },
  mounted() {
    this.GetDetail();
  },
  created() {},
};
</script>

  <style scoped>
.detail-box {
  width: 54vw;
  height: 90vh;
  float: right;
  overflow: hidden;
}
.detail-title {
  width: 99%;
  text-align: center;
  font-size: 17px;
  float: right;
  margin: 3px 5px;
  color: #00bb3e;
  background-color: #fff;
  padding: 5px 0px;
}
</style>
