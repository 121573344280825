<template>
    <div class="main-box">
    </div>
  </template>
    
    <script>
  export default {
    components: {},
    data() {
      return {};
    },
    computed: {},
    methods: {},
    mounted() {},
    created() {},
  };
  </script>
    
    <style scoped>
  .main-box {
    width: 100%;
    height: 83vh;
    float: right;
    overflow: hidden;
  }
  </style>